import React, { Suspense } from "react"
import { graphql } from "gatsby"

import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { useTranslation } from 'gatsby-plugin-react-i18next'

import SmoothScroll from "../components/layout/smoothScroll";

import Layout from '../components/layout/layout'
import MenuGlobal from '../components/menu'

import '../sass/portfolio.scss';
import SmallHeightMarginWidth from "../components/portfolio/blocks/galleries/smallHeightMarginWidth";

const PortfolioIndividualTemplate = ({ pageContext, location }) => {

  console.log(pageContext)

  const TopParallax = React.lazy(() => import("../components/portfolio/blocks/topSection/topParallax"));
  const AreasTitleText = React.lazy(() => import("../components/portfolio/blocks/texts/areasTitleText"));
  const SliderWithBackgroundImageWithColor = React.lazy(() => import("../components/portfolio/blocks/sliders/sliderWithBackgroundImageWithColor"));
  const FullWidthImageZoom = React.lazy(() => import("../components/portfolio/blocks/fullWidthImage/fullWidthImageZoom"));
  const SameHeightTitleText = React.lazy(() => import("../components/portfolio/blocks/texts/sameHeightTitleText"));
  const TwoImagesDifferentsSpeeds = React.lazy(() => import("../components/portfolio/blocks/galleries/twoImagesDifferentsSpeeds"));
  const TwoImagesDifferentsSpeedsMobile = React.lazy(() => import("../components/portfolio/blocks/galleries/twoImagesDifferentsSpeedsMobile"));
  const SmallHeightfullWidth = React.lazy(() => import("../components/portfolio/blocks/galleries/smallHeightfullWidth"));
  const SliderCardsHorizontalWithDrag = React.lazy(() => import("../components/portfolio/blocks/sliders/sliderCardsHorizontalWithDrag"));
  const TextLeftSliderRightWithOneAndHalfSlides = React.lazy(() => import("../components/portfolio/blocks/sliders/textLeftSliderRightWithOneAndHalfSlides"));
  const TextLeftSliderRightWithOneAndHalfSlidesMobile = React.lazy(() => import("../components/portfolio/blocks/sliders/textLeftSliderRightWithOneAndHalfSlidesMobile"));
  const VideoInViewPlayAndPauseWithBorderRadius = React.lazy(() => import("../components/portfolio/blocks/videos/videoInViewPlayAndPauseWithBorderRadius"));
  const FullWidthImage = React.lazy(() => import("../components/portfolio/blocks/fullWidthImage/fullWidthImage"));


  const { t } = useTranslation()
  const breakpoints = useBreakpoint();

  const mobile = breakpoints.mobile

  var seoGeneral = t("portfolio", { returnObjects: true }).seo
  var seo = {
    title: pageContext.project.projetos.seo?.title || seoGeneral.title,
    description: pageContext.project.projetos.seo?.description || seoGeneral.description,
    image: pageContext.project.featuredImage.node.sourceUrl || seoGeneral.image
  }

  return (
    <Layout mobile={mobile} post={null} data={t("global", { returnObjects: true })} page={t("portfolio", { returnObjects: true }).page} seo={seo} location={location}>
      {mobile ?
        <>
          <MenuGlobal noFilter location={location} portfolioIndividual
            global={t("global", { returnObjects: true })} centered={true} mobile noScroll />

          <SmoothScroll noTopSpace>

            <div className="container-scroll-change-bg" style={{ background: 'linear-gradient(180deg, #EDEDED 0%, #F8F4EF 100%)' }}>
              {pageContext.project.projetos.blocks
                .map((block, index) => {
                  switch (block.number) {
                    case 2:
                      return (
                        <Suspense >
                          <SmallHeightMarginWidth data={block} />
                        </Suspense>
                      )
                    case 3:
                      return (
                        <Suspense >
                          <SmallHeightfullWidth data={block} />
                        </Suspense>
                      )
                    case 4:
                      return (
                        <Suspense >
                          <FullWidthImage data={block} />
                        </Suspense>
                      )
                    case 8:
                    default:
                      return (
                        <Suspense >
                          <TopParallax data={block} />
                        </Suspense>
                      )
                    case 9:
                      return (
                        <Suspense >
                          <AreasTitleText data={block} mobile />
                        </Suspense>
                      )
                    case 10:
                      return (
                        <Suspense >
                          <SliderWithBackgroundImageWithColor data={block} />
                        </Suspense>
                      )
                    case 11:
                      return (
                        <Suspense >
                          <FullWidthImageZoom data={block} />
                        </Suspense>
                      )
                    case 12:
                      return (
                        <Suspense >
                          <SameHeightTitleText data={block} />
                        </Suspense>
                      )
                    case 13:
                      return (
                        <Suspense >
                          <TwoImagesDifferentsSpeedsMobile data={block} />
                        </Suspense>
                      )
                    case 14:
                      return (
                        <Suspense >
                          <SliderCardsHorizontalWithDrag data={block} mobile />
                        </Suspense>
                      )
                    case 15:
                      return (
                        <Suspense >
                          <TextLeftSliderRightWithOneAndHalfSlidesMobile data={block} />
                        </Suspense>
                      )
                    case 16:
                      return (
                        <Suspense >
                          <VideoInViewPlayAndPauseWithBorderRadius data={block} />
                        </Suspense>
                      )
                    case 17:
                      return (
                        <Suspense >
                          <TextLeftSliderRightWithOneAndHalfSlidesMobile data={block} video />
                        </Suspense>
                      )
                  }
                })}
            </div>


          </SmoothScroll>

        </>
        :
        <>
          <MenuGlobal noFilter location={location} portfolioIndividual
            global={t("global", { returnObjects: true })} centered={true} noScroll>

            <SmoothScroll noTopSpace>

              <div className="container-scroll-change-bg" style={{ background: 'linear-gradient(180deg, #EDEDED 0%, #F8F4EF 100%)' }}>

                {pageContext.project.projetos.blocks
                  .map((block, index) => {
                    switch (block.number) {
                      case 2:
                        return (
                          <Suspense >
                            <SmallHeightMarginWidth data={block} />
                          </Suspense>
                        )
                      case 3:
                        return (
                          <Suspense >
                            <SmallHeightfullWidth data={block} />
                          </Suspense>
                        )
                      case 4:
                        return (
                          <Suspense >
                            <FullWidthImage data={block} />
                          </Suspense>
                        )
                      case 8:
                      default:
                        return (
                          <Suspense >
                            <TopParallax data={block} />
                          </Suspense>
                        )
                      case 9:
                        return (
                          <Suspense >
                            <AreasTitleText data={block} />
                          </Suspense>
                        )
                      case 10:
                        return (
                          <Suspense >
                            <SliderWithBackgroundImageWithColor data={block} />
                          </Suspense>
                        )
                      case 11:
                        return (
                          <Suspense >
                            <FullWidthImageZoom data={block} />
                          </Suspense>
                        )
                      case 12:
                        return (
                          <Suspense >
                            <SameHeightTitleText data={block} />
                          </Suspense>
                        )
                      case 13:
                        return (
                          <Suspense >
                            <TwoImagesDifferentsSpeeds data={block} />
                          </Suspense>
                        )
                      case 14:
                        return (
                          <Suspense >
                            <SliderCardsHorizontalWithDrag data={block} />
                          </Suspense>
                        )
                      case 15:
                        return (
                          <Suspense >
                            <TextLeftSliderRightWithOneAndHalfSlides data={block} />
                          </Suspense>
                        )
                      case 16:
                        return (
                          <Suspense >
                            <VideoInViewPlayAndPauseWithBorderRadius data={block} />
                          </Suspense>
                        )
                      case 17:
                        return (
                          <Suspense >
                            <TextLeftSliderRightWithOneAndHalfSlides data={block} video />
                          </Suspense>
                        )
                    }
                  })}

                {/**<Suspense >
                  <FullscreenSlider />
                </Suspense>*/}
              </div>

            </SmoothScroll>
          </MenuGlobal>
        </>
      }


    </Layout>
  )
}



export default PortfolioIndividualTemplate


export const pageQuery = graphql`
    query($language: String!) {
      locales: allLocale(filter: {ns: {in: ["global", "portfolio"]}, language: {eq: $language}}) {
      edges {
      node {
      ns
          data
          language
        }
      }
    }
    allFile {
      edges {
        node {
          relativePath
          publicURL
        }
      }
    }
  }
`